<template>
    <slot v-if="!noButton" :open="modalRef?.show">
        <button @click="() => modalRef.show()" v-bind="$attrs">{{$t('Share with')}}</button>
    </slot>
    <OModal ref="modalRef" @show="handleShown" @hidden="handleHidden">
        <div class="modal-dialog modal-lg"  >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fs-5">{{$t('Share with Persons')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                   <ODataGrid :dataObject="dsSharedWith" hideGridMenu showNewRecordsPanel>
                        <OColumn name="PersonName" editable width="400" flexWidth="100" disableResize>
                           <template #editor="{row}">
                                <OPersonsLookup v-model="row.PersonName" :whereClause="`ID <> ${userSession.personId}`" 
                                    :bind="(sel)=>{row.Person_ID = sel.ID; row.PersonName = sel.Name; row.Layout_ID = props.layoutId }"/>
                            </template>
                        </OColumn>
                   </ODataGrid>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">{{$t('Close')}}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup lang="ts">
import OModal from 'o365.vue.components.Modal.vue';
import OPersonsLookup from  'o365.vue.components.PersonsLookup.vue';
import { getOrCreateDataObject } from 'o365.vue.ts';
import { userSession } from 'o365.modules.configs.ts';
import { ref } from 'vue';

const props = defineProps<{
    layoutId: number;
    noButton: boolean;
}>();

const emit = defineEmits<{
    (e: 'hidden' ): void
}>();

const modalRef = ref<OModal|null>(null);
const dsSharedWith = getOrCreateDataObject({
    id: 'o_dsLayouts_SharedWith',
    appId: 'site',
    viewName: 'sviw_O365_SharedLayouts',
    uniqueTable: 'stbv_O365_SharedLayouts',
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    maxRecords: 50,
    fields: [
        { name: 'PrimKey' }, 
        { name: 'ID', type: 'number' },
        { name: 'Person_ID', type: 'number' },
        { name: 'Layout_ID', type: 'number' },
        { name: 'PersonName' }
    ]
});


function handleShown() {
    if (props.layoutId) {
        dsSharedWith.recordSource.whereClause = `[Layout_ID] = '${props.layoutId}'`;
        dsSharedWith.load();
    } else {
        dsSharedWith.storage.clearItems();
        dsSharedWith.batchData.storage.clearItems();
    }
}

function handleHidden() {
    dsSharedWith.storage.clearItems();
    dsSharedWith.batchData.storage.clearItems();
    emit('hidden');
}

function show() {
    if (modalRef.value?.show) {
        modalRef.value.show();
    }
}

defineExpose({ modalRef, show});
</script>